import { AppstoreOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { APP_PREFIX_PATH, } from "configs/AppConfig";
import { isAuthenticated } from "../../src/views/app-views/apps/e-commerce/auth";

let { user, token } = isAuthenticated();

// side navigation for role 1 admin
const appsNavTree = [
  {
    key: "apps",
    path: `${APP_PREFIX_PATH}/apps`,
    title: "sidenav.apps",
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "apps-ecommerce",
        path: `${APP_PREFIX_PATH}/apps/ecommerce`,
        title: "sidenav.apps.ecommerce",
        icon: ShoppingCartOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "apps-ecommerce-orders",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/orders`,
            title: "sidenav.apps.ecommerce.orders",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-ecommerce-orderCalendar",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/order-calendar`,
            title: "Order Calendar",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-ecommerce-users",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/users`,
            title: "Users",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-ecommerce-productList",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/product-list`,
            title: "Products",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-ecommerce-productMaster",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/product-master-list`,
            title: "Products Master",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-ecommerce-receivng-request",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/receiving-request-list`,
            title: "Receiving Request",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          
          {
            key: "apps-ecommerce-deliveryRoutes",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/delivery-routes`,
            title: "Delivery Routes",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-ecommerce-business-order-list",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/business-order-list`,
            title: "Business Order List",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-ecommerce-business-productList",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/cc/product-list`,
            title: "Business Products",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-ecommerce-business-list",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/business-list`,
            title: "Business List",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-ecommerce-oksupers",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/super-plans`,
            title: "Super Plans",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-ecommerce-banner",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/banner`,
            title: "Banners",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-ecommerce-list-notifications",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/list-notifications`,
            title: "Campaign Notifications",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },

          {
            key: "apps-ecommerce-app",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/app-collection`,
            title: "App Collection",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-ecommerce-deliveryAgent",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/delivery-agent`,
            title: "Delivery Agents",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-ecommerce-addCategory",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/list-categories`,
            title: "Categories",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          
          {
            key: "apps-ecommerce-vendors",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/vendors`,
            title: "Farmer",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-ecommerce-collectionCenter",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/cc-list`,
            title: "Collection Center",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
         
       
         
          {
            key: "apps-ecommerce-grn",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/grn`,
            title: "GRN",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-ecommerce-otp",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/otp-list`,
            title: "OTP",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
];

// side navigation for role 2 admin
const adminRoleTowNavTree = [
  {
    key: "apps",
    path: `${APP_PREFIX_PATH}/apps`,
    title: "sidenav.apps",
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "apps-ecommerce",
        path: `${APP_PREFIX_PATH}/apps/ecommerce`,
        title: "sidenav.apps.ecommerce",
        icon: ShoppingCartOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "apps-ecommerce-receivng-request",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/receiving-request-list`,
            title: "Receiving Request ",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-ecommerce-farmerList",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/cc/vendor-list`,
            title: "Farmer List",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
];

let displayNavTree = user && user.role == 2 ? adminRoleTowNavTree : appsNavTree;
if(user && user.displayBusinessNavIds && user.displayBusinessNavIds.includes(user._id)){

  displayNavTree=[{
    key: "apps",
    path: `${APP_PREFIX_PATH}/apps`,
    title: "sidenav.apps",
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "apps-ecommerce",
        path: `${APP_PREFIX_PATH}/apps/ecommerce`,
        title: "sidenav.apps.ecommerce",
        icon: ShoppingCartOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "apps-ecommerce-receivng-request",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/receiving-request-list`,
            title: "Receiving Request ",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-ecommerce-farmerList",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/cc/vendor-list`,
            title: "Farmer List",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-ecommerce-business-list",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/business-list`,
            title: "Business List",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-ecommerce-productList",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/cc/product-list`,
            title: "Business Products",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-ecommerce-business-order-list",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/business-order-list`,
            title: "Business Order List",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },]
}

const navigationConfig = [...displayNavTree];


export default navigationConfig;
