import { API } from "../config"

const prasukAuthService = {}

prasukAuthService.signup = (user) => {
	return fetch(`${API}/signup`, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		body: JSON.stringify(user),
	})
		.then((response) => {
			return response.json()
		})
		.catch((err) => {
			console.log(err)
		})
}

// prasukAuthService.signin = (user) => {
//   return fetch(`${API}/signin`, {
//     method: "POST",
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(user),
//   })
//     .then((response) => {
//       return response.json();
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// };

prasukAuthService.signin = (user) => {
	return fetch(`${API}/verifyotp`, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		body: JSON.stringify(user),
	})
		.then((response) => {
			if (response.status === 401) {
				alert("OTP doesn't match")
			} else if (response.status === 403) {
				alert("Access denied! Contact Tech team")
			}
			// console.log("response", response, response.status)
			else if (response.status === 200) {
				return response.json()
			}
		})
		.catch((err) => {
			console.log(err, err.status)
		})
}

export const requestOtp = (user) => {
	return fetch(`${API}/requestotp`, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		body: JSON.stringify(user),
	})
		.then((response) => {
			return response.json()
		})
		.catch((err) => {
			console.log("error", err)
		})
}

export const verifyOtp = (user) => {
	return fetch(`${API}/verifyotp`, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		body: JSON.stringify(user),
	})
		.then((response) => {
			console.log("resp", response.status, response)
			// return response.json()
		})
		.catch((err) => {
			console.log(err.status, err)
		})
}

prasukAuthService.authenticate = (data, next) => {
	if (typeof window !== "undefined") {
		localStorage.setItem("jwt", JSON.stringify(data))
		next()
	}
}

prasukAuthService.signout = (next) => {
	if (typeof window !== undefined) {
		localStorage.removeItem("jwt")
		next()
		return fetch(`${API}/signout`, {
			method: "GET",
		})
			.then((response) => {
				// console.log("signout", response)
			})
			.catch((err) => console.log(err))
	}
}

prasukAuthService.isAuthenticated = () => {
	if (typeof window == "undefined") {
		return false
	}

	if (localStorage.getItem("jwt")) {
		return JSON.parse(localStorage.getItem("jwt"))
	} else {
		return false
	}
}

export default prasukAuthService
